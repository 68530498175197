import React, { useContext } from "react";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import NavBar from "./layouts/NavBar";
import Footer from "./layouts/Footer";

import ScrollToTop from "./helpers/ScrollToTop";

import "./styles/global.scss";

import Homepage from "./pages/Homepage";
import About from "./pages/About";

import Services from "./pages/Services";
import ServicesDataCollection from "./pages/ServicesDataCollection";
import ServicesDataManagement from "./pages/ServicesDataManagement";
import ServicesDataScience from "./pages/ServicesDataScience";
import ServicesDataPresentation from "./pages/ServicesDataPresentation";

import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";

import Work from "./pages/Work";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import { BlogListContext } from "./pages/blog/BlogList";
import { WorkListProvider } from "./pages/work/WorkList";

import Tuenti from "./pages/work/TuentiArgentina";
import Reath from "./pages/work/Reath";
import O2Wifi from "./pages/work/O2Wifi";
import InsureAndGo from "./pages/work/InsureAndGo";
import Error404 from "./pages/Error404";

import CookieConsent from "react-cookie-consent";
import { createBrowserHistory } from "history";

function App() {
  var history = createBrowserHistory();

  history.listen((location) => {
    window.ga("set", "page", location.pathname + location.search);
    window.ga("send", "pageview");
  });

  const [blogList] = useContext(BlogListContext);


  return (
    <Router history={history}>
      <div className="App">
        <NavBar />
        <ScrollToTop />

        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/about" exact component={About} />
          <Route path="/services" exact component={Services} />
          <Route
            path="/services/data-collection"
            exact
            component={ServicesDataCollection}
          />
          <Route
            path="/services/data-management"
            exact
            component={ServicesDataManagement}
          />
          <Route
            path="/services/data-science"
            exact
            component={ServicesDataScience}
          />
          <Route
            path="/services/data-delivery"
            exact
            component={ServicesDataPresentation}
          />

          <Route path="/blog" exact component={Blog} />

          {blogList.map((blog, idx) => (
            <Route path={"/blog/" + blog.url} exact key={idx}>
              <BlogPost URL={blog.url} />
            </Route>
          ))}

          <Route path="/work" exact component={Work} />
          <Route path="/work/tuenti" component={Tuenti} />
          <Route path="/work/reath" component={Reath} />
          <Route path="/work/o2-wifi" component={O2Wifi} />
          <Route path="/work/insure-and-go" component={InsureAndGo} />

          <Route path="/contact" exact component={Contact} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="*" exact component={Error404} />

          <CookieConsent
            location="bottom"
            buttonText="✖"
            cookieName="cookie_consent"
            buttonStyle={{
              color: "#fff",
              background: "none",
              borderColor: "#fff",
              borderWidth: "1px",
              fontSize: "0.75rem",
              border: "none",
              padding: "0.1rem 1rem",
              margin: "0 1rem 0 0",
              borderRadius: "40px",
            }}
            style={{
              background: "#292929",
              height: "2.7rem",
              padding: 0,
              margin: 0,
              fontSize: "0.7rem",
            }}
            expires={150}
          >
            By using our website, you agree to our{" "}
            <a href="/privacy-policy" className="text-white">
              <u>cookie and privacy policy.</u>
            </a>
          </CookieConsent>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
